<template>
  <Logo />

  <!-- <b-img :src="appLogoImage" alt="logo" height="100px" /> -->
</template>
<script>
import Logo from "@/assets/images/logo/logo.svg";
export default {
  components: {
    Logo,
  },
};
</script>
